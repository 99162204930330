.headBox {
    min-height: 100vh;
    border-top-left-radius: 50px;
    position: fixed;
    overflow-y: auto;
    top: 69px;
    z-index: 1;
    background-color: #e9e9e9;
  }


.boxes {
    background-color: white;
    border-radius: 15px;
    height: 300px;
    width: 300px;
    display: flex;
    margin-bottom: 50px;
    flex-direction: column;
    /* position: relative;
    left: 12.5%; */
}

.hubBoxes {
    width: 80px;
    height: 80px;
    position: relative;
    bottom: 37px;
    left: 40px;
    display: flex;
    justify-content: center;
}

.imgStyleBoxes {
    box-sizing: content-box;
}

.boxes:nth-child(4) h4 {
    font-size: 1.19rem !important;
}

.widthParentApps {
    width: 260px !important;
}

.boxText {
    position: relative;
    bottom: 15px;
}

.iconActive {
    width: 40px;
    height: 40px;
    background-color: #b5b5b5;
    border-radius: 5px;
    margin-bottom: 10px;
}

.iconActivePNG {
    width: 48px;
    height: 48px;
    background-color: #b5b5b5;
    border-radius: 5px;
}

.icons {
    width: 35px;
    height: 35px;
    padding-top: 0px;
    padding-left: 0px;
}

.iconsSVG {
    width: 100%;
    height: 100%;
}

.iconsSVG2 {
    /* margin-top: 0.40rem; */
    width: 40px;
    height: 40px;
}

.DashBoxIconCenteren {
    display: flex;
    justify-content: center;
    align-items: center;
}

.boxInvisible{
    height: 300px;
    width: 300px;
}

.subTitle {
    font-size: x-small;
    width: 200px;
    color: gray;
}

.appstoreBox {
    background-color: #e6e6e6;
    color: #919191;
    height: 40px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    width: 100px;
}

.appstoreText {
    background-color: #e6e6e6;
    color: #919191;
    height: 40px;
    width: 50px;
    font-size: small;
    font-weight: 500;
}

.responsiveDashboard {
    margin-bottom: 20px;
    margin-top: 20px;
}

.widthRowBoxes {
    width: 98%;
}

.dashboard-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Ensure 4 items per row on wider screens */
    margin-top: 40px;
}

.dashboard-container > div {
    justify-self: center;
}



@media only screen and (max-width: 1530px) {
    .dashboard-container {
        grid-template-columns: repeat(3, 1fr); 
    }
}

@media only screen and (max-width: 1200px) {
    .dashboard-container {
        grid-template-columns: repeat(2, 1fr); 
    }
}

@media only screen and (max-width: 650px) {
    .dashboard-container {
        grid-template-columns: repeat(1, 1fr); 
    }
}