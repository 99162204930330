.brandDrag {
  width: 100%;
}

.brandDrag > div > .tableBackground > .table-drag td {
  width: 35vw !important;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc !important;
  border-top: 1px solid #ccc !important;
}

.brandDrag > div > .tableBackground > .table-drag td:first-child {
  width: 0.1vw !important;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc !important;
  border-top: 1px solid #ccc !important;
}

.brandDrag > div > .tableBackground > .table-drag td:nth-of-type(5) {
  width: 2vw !important;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc !important;
  border-top: 1px solid #ccc !important;
}

.brandDrag > div > .tableBackground > .table-drag td:nth-of-type(6) {
  width: 2vw !important;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc !important;
  border-top: 1px solid #ccc !important;
}

.brandDrag > div > .tableBackground > .table-drag td:nth-of-type(4) {
  width: 35vw !important;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc !important;
  border-top: 1px solid #ccc !important;
}
.brandDrag > div > .tableBackground > .table-drag td:nth-of-type(2) {
  width: 4vw !important;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc !important;
  border-top: 1px solid #ccc !important;
}