.productInputWidth {
    width: 100%;
    border: 1px solid #cbcbcb !important;
}

.borderInputs {
  border: 1px solid #cbcbcb !important;
}

.saveButton {
    min-width: fit-content;
    height: 35px !important;
    line-height: 20px !important;
}

.saveAndNieuw {
  background-color: #79b893;
  color: white;
  font-weight: 500;
  border-radius: 50px;
}

.saveAndNieuw:focus, .saveAndNieuw:active , .saveAndNieuw:hover {
  background-color: #5b8a6e !important;
  color: white !important;
  font-weight: 500 !important;
  border-radius: 50px !important;
}

.saveProduct {
  background-color: #79cffb;
  color: white;
  font-weight: 500;
  border-radius: 50px;
}

.saveProduct:focus, .saveProduct:active , .saveProduct:hover {
  background-color: #65afd4 !important;
  color: white !important;
  font-weight: 500 !important;
  border-radius: 50px !important;
}

.plusStyle {
  padding-top: 4px;
  margin-left: 3px;
  cursor: pointer;
  margin-bottom: 0px;
  height: fit-content !important;
  font-size: larger;
  color: #6a6a6a;
  font-weight: 600;
}

.tableActive, .widthTableAll {
  position: relative;
  bottom: 30px;
}

.formItems {
  width: 95%;
}

.parentContainer {
  width: 100%;
}

.inputBarcode::placeholder {
  color: #c2c2c2;
  font-size: small !important;
}

.productNumBarCodeBrandInputs {
  min-width: 150px;
  width: 290px;
  border: 1px solid #cbcbcb !important;
}

.productOptions {
  width: 150px;
  height: 18px;
  font-size: xx-small;
  position: relative;
  background-color: #f8f8f8 !important;
  top: 5px;
  line-height: 10.5px;
  appearance: none; /* Removes default browser styling */
  -webkit-appearance: none; /* Specifically for WebKit browsers */
  background-image: url('../../../Customer/AddCustomers/Icons/DrowpdownArrowModals.svg'); /* Your custom arrow image */
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%; /* Moves the arrow right */
  background-size: 5px;
}

.lineCategories {
  height: 2px;
  width: 14px;
  background-color: black;
}

.checkbox-containerSimple {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  -webkit-user-select: none;
  user-select: none;
}

.checkbox-containerSimple input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmarkSimple {
  position: absolute;
  top: 3px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #c9c9c9;
}

.textEditor {
    width: 50vw;
}

.widthBoxes {
    width: 20vw;
    min-height: fit-content !important;

}

.genderBox {
    height: 25vh;
}

.placeholderStyle::placeholder {
    color: rgb(150, 150, 150);
    font-size: small;
  }

.standardSalePrice {
    width: 125px;
    border: 1px solid #cbcbcb !important;
}

.widthPrice {
  width: 17vw;
}

.widthPriceStandardSale {
  width: 13vw !important;
}

.widthInput {
  width: 130px !important;
  height: 25px !important;
  font-size: small;
  border: 1px solid #cbcbcb !important;
  color: black;
  background-color: white;
}

.gearButtonPrices {
  background-color: white;
  width: fit-content;
  height: fit-content;
  border: 1px solid white;
  outline: none;
}

.gearButtonPrices:hover, .gearButtonPrices:focus {
  background-color: #ffffff !important;
  border: 1px solid white;
  outline: none;
}

.fitContent {
  width: fit-content !important;
}

.minFitContent {
  min-width: fit-content !important;
}

.tox .tox-toolbar--scrolling {
  flex-wrap: wrap !important;
}

.bottomText {
    font-size: x-small;
    font-weight: bold;
    padding-left: 10px;
    width: fit-content;
}

.buyPrice {
  height: 35px;
}

.buyPrice:disabled {
    background-color: #e5e5e5;
    color: black;
}

.buyPrice::placeholder {
  color: black;
}

.widthBoxUploader {
  width: 55vw !important;
}

.fileUploaderWidthHeight {
    width: 100%;
    display: flex;
    height: fit-content;
}

.tableBackgroundVariations {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
  }

  .backgroundForm {
    background-color: white;
}

.switch {
    position: relative;
    display: inline-block;
    min-width: 70px;
    height: 30px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #cfcfcf00;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 27px;
    width: 27px;
    left: -0px;
    top: 0.7px;
    background-color: #79cffb;
    -webkit-transition: .4s;
    transition: .4s;
  }

  th {
    white-space: nowrap;
  }
  
  input:checked + .slider {
    background-color: #dcf3fe;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #efefef;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(35px);
    -ms-transform: translateX(35px);
    transform: translateX(40px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
    border: 1px solid #efefef;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .parentBoxSellChannelProduct {
    display: flex;
    flex-direction: row !important;
    transition: ease all 0.5s !important;
  }

  .widthColorTable > div:nth-child(3) {
    display: none !important;
  }

  .widthColorTable > div:nth-child(2) {
    padding: 15px 0px;
  }

  .widthColorTable thead > tr > th:nth-child(3) {
    display: flex;
    justify-content: center;
  }

  .widthColorTable thead > tr > th:nth-child(4) {
    text-align: center;
  }

  .widthColorTable thead > tr > th:nth-child(6) {
    text-align: center;
  }

  .widthColorTable tbody > tr > td:nth-child(4) {
    display: flex;
    justify-content: center;
  }

  .widthColorTable tbody > tr > td:nth-child(6) {
    display: flex;
    justify-content: center;
  }

  .widthColorTable > div:first-child > div > div > div > svg {
    display: none;
  }

  .widthColorTable > div:first-child > div > div > div {
    background-color: #f8f8f8;
  }

  .widthColorTable > div:first-child > div > div > div:first-child {
    appearance: none;
    /* Removes default browser styling */
    -webkit-appearance: none;
    /* Specifically for WebKit browsers */
    background-image: url('../../../Customer/AddCustomers/Icons/DrowpdownArrowModals.svg');
    /* Your custom arrow image */
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) 50%;
    /* Moves the arrow right */
    background-size: 10px;
  }

  .stockBox {
    border: 2px solid #e5e5e5;
    padding: 8px 15px;
    border-radius: 5px;
  }

  .actionOptions {
    line-height: 15px;
    text-align: start !important;
    padding-left: 20px !important;
  }

  .formVariationProduct {
    width: 100%;
    padding-right: 20px;
    min-height: fit-content;
    height: fit-content;
  }

  .boxSellChannel {
    min-width: fit-content;
    height: fit-content;
    width: 100%;
  }

  .pricesWidth {
    width: 45vw;
    position: relative;
    left: 40px;
  }

  .text-small {
    font-size: small;
  }

  .pricesTitle {
    width: 53vw;
  }

  .mediaUploaderBox {
    width: 30vw;
    height: inherit;
    margin: 0px !important;
  }

  .hide {
    display: none !important;
  }

  .colorsHover {
    transition: background-color 0.3s ease;
    border-radius: 5px;
  }
  
  .colorsHover:hover {
    background-color: #c2c2c2;
    cursor: pointer;
  }

  .colorsBox {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
    overflow: auto;
    max-height: 200px; /* Adjust the maximum height as needed */
  }

  .colorsBox::-webkit-scrollbar {
    width: 8px; /* Width of the scroll bar */
  }
  
  .colorsBox::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scroll bar thumb */
    border-radius: 4px; /* Radius of the scroll bar thumb */
  }
  
  .colorsBox::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the scroll bar thumb on hover */
  }
  
  .colorsBox::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Color of the scroll bar track */
  }
  
  .colorItem {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  
  .colorContent {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .categories {
    width: 100% !important;
  }

  .categoriesParent {
    width: 100%;
  }

  .optionSize {
    border-right: 15px solid transparent !important;
  }
  
  .colorCircle {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #cccccc;
  }

  .whiteBox.colorsBox.colorGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Adjust for the desired number of columns */
    gap: 10px;
    width: 100%;
  }

  .colorItem {
    display: grid;
    grid-template-columns: auto 1fr; /* Circle takes only the necessary space, and name takes the rest */
    gap: 5px; /* Space between circle and name */
    align-items: center;
  }

  .colorCircleWrapper {
    display: flex;
    justify-content: center; /* Ensures the circle is always centered horizontally */
  }


  .colorName {
    overflow: hidden;
    white-space: wrap;
    max-width: 100%;
  }
  
  .smallText {
    font-size: small;
    max-width: 150px;
    color: rgb(199, 199, 199);
  }

  .webkitRemove::-webkit-outer-spin-button,
  .webkitRemove::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .textEditorSimple > div > div > div:nth-child(3) > div > div > div {
    display: none;
  }

  .textEditorVariation > div > div > div:nth-child(3) > div > div > div {
    display: none;
  }

  body > div > div:nth-child(2) > div:first-child[role="alert"]{
    display: none !important;
  }

  .languageSelect {
    border-radius: 15px;
    border: 1px solid #cbcbcb;
    color: black;
    background-color: white;
  }

  .textlist {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .textlist {
    vertical-align: -4px;
  }

  .tablePresetBackground {
    overflow: auto;
  }



  .colorNameVariationTable {
    max-width: 100px;
    text-overflow:ellipsis;
    overflow: hidden;
  }

  .tableDropdown {
    border-radius: 15px !important;
    border: none;
    background-color: #f8f8f8;
    padding-left: 10px !important;
    padding-right: 25px !important;
  }

  .parentSaveButtons {
    width: 100% !important;
  }

  .genderPrices {
   margin-left: 1.5rem; 
  }
  
  .sellChannelCategories {
    margin-left: 1.5rem;
  }

  .fitcontentHeight {
    height: fit-content;
    min-height: fit-content;
  }

  @media only screen and (max-width: 1530px) {
    .parentContainer {
      display: flex;
      flex-direction: column !important;
    }

    .widthSellChannel {
      width: fit-content !important;
      margin-top: 2rem;
    }

    .widthGender {
      margin-left: 0rem !important;
      margin-bottom: 0rem !important;
      width: 25% !important;
    }

    .widthProductStatus {
      margin-top: 2rem;
      width: 21vw !important;
      min-width: fit-content !important;
    }

    .parentCheckboxes {
      display: flex;
      width: 75.5vw;
      margin-bottom: 20px;
      flex-direction: row !important;
      justify-content: space-between;
      margin-right: 1.75rem;
      flex-wrap: wrap;
      padding-right: 10px !important;
    }

    .sellChannelMedia {
      flex-direction: column !important;
      justify-content: space-between;
    }

    .genderPrices {
      margin-bottom: 1rem !important;
      flex-direction: row !important;
      margin-left: 0rem !important;
    }

    .sellChannelCategories {
      height: 300px;
      flex-direction: row !important;
      margin-left: 0rem !important;
    }

    .textEditorVatiation {
      width: 55vw;
    }

    .formSimpleProduct {
      width: 75vw;
    }

    .productAndSellChannel {
      display: flex;
      flex-direction: column !important;
      /* margin-left: 15px;
      margin-right: 15px; */
    }

    .boxSellChannel {
      margin-left: 0px !important;
      min-width: fit-content;
      height: inherit;
      margin-bottom: 0px !important;
    }

    .parentSaveButtons {
      width: 75vw !important;
    }

    .widthBoxUploader {
      width: 74vw !important;
  }
  }

 @media only screen and (max-width: 1245px) {

    .productNumSelectLists {
      display: flex;
      flex-direction: column !important;
    }

    .productNumBarCodeBrandInputs {
      width: 100%;
    }
  }


  @media only screen and (max-width: 570px) {

    .genderPrices {
      flex-direction: column !important;
    }

    .widthGender {
      margin-right: 0px !important;
      margin-bottom: 16px !important;
      width: 100% !important;
    }

    .sellChannelCategories {
      flex-direction: column !important;
      height: fit-content;
    }

    .boxPrizes {
      margin-bottom: 0px !important;
    }

    .boxSellChannel {
      margin-bottom: 16px !important;
    }

    .boxCategories {
      margin-bottom: 16px;
    }
  }