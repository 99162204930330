:root {
  --tumb: red;
}

.productTable tbody > tr > td:nth-child(2) {
  display: flex;
  justify-content: center;
}

.productTable thead> tr > th:nth-child(2) {
  width: 17%;
}

.productTable thead> tr > th:nth-child(4) {
  width: 13%;
}

.productTable thead> tr > th:nth-child(5) {
  width: 13%;
}

.addProductButtons {
  display: flex;
  flex-direction: column !important;
}

.allProductPage {
  margin-right: 5.5rem;
}

.btn-custom {
    /* border: 2px solid #9d9d9d; */
    padding: 10px;
    border-radius: 200px;
    font-weight: 600;
    display: flex;
    align-items: center;
    /* width: 100px; */
    height: 40px;
    margin-right: 0px !important;
    margin: 20px;
    background-color: white;
    text-decoration: none;
    color: black;
    height: 40px;
    outline: none;
}

.btn-custom2, .btn-custom2:focus {
    border: 1px solid #9d9d9d;
    padding: 10px;
    /* width: 100px; */
    height: 40px;
    font-weight: 600;

    align-items: center !important;
    padding-bottom: 10px !important;
    display: flex !important;
    margin-left: 0px !important;
    margin: 20px;
    background-color: white;
    text-decoration: none;
    color: black;
    outline: none;
}

.btn-custom3 {
  background-color: white;
  height: 40px;
  color: #9d9d9d;
  display: flex;
  align-self: center;
  border: 1px solid #9d9d9d;
  padding: 10px;
  align-items: center;
}

.btn-custom3 > input:focus, .btn-custom3 > input {
  outline: none;
  font-weight: 600 !important;
}

.btn:disabled {
    /* background-color: black; */
    color: #c5c5c5;
}

.buttonContainer {
  display: flex !important;
  justify-content: center !important;
}

.buttonVariation {
  margin-right: 20px !important;
}

.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: white;
    border: 1px solid #9d9d9d;
  }

.checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
  }

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

.checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

.checkmark:after {
    left: 8px;
    top: 2px;
    width: 8px;
    height: 16px;
    border: solid black;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg) scale(1.3);
  }

.colorAddBox {
  width: 30vw;
  margin-right: 0px !important;
  min-width: fit-content;
  min-height: fit-content !important;
}

.colorCircle {
  height: 25px;
  width: 25px;
}

.colorInput {
  position: relative;
  /* left: 200px; */
  visibility: hidden;
}

/* .colorpickerIcon {
   height: 20vh;
  position: relative;
  top: 175px;
  left: 10px; 
} */


.iconEyedropper {
  cursor: pointer;
}

.infoModal {
  /* position: relative;
  bottom: 40px; */
  min-height: fit-content !important;
  min-width: fit-content !important;
  width: fit-content;
}

.optionListWidth {
  width: 155px;
  height: 30px;
  line-height: 25px;
  outline: 1px solid #9d9d9d !important;
}

.productOverview {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
}

.productTable > div {
    margin-bottom: 20px;
}

.rangeColor {
  border: 1px solid #000000;
  -webkit-appearance: none;
  appearance: none;
}

.selectContainerPresetStyle {
  align-self: center;
}

.tableBackground {
  background-color: white;
  padding: 20px;
  border-radius: 40px;
}

.tableSearchBar {
  width: 100%;
}

/* .tableSearchBarContainer {
  margin-bottom: 0px;
} */

.tableAddVariations {
  width: 60vw;
}

.titleModal {
  width: 350px !important;
}

.eyedropperStyle > div > button {
  background-color: rgb(255, 255, 255);
  color: #000000;
  width: fit-content;
  padding: 0px !important;
}

/* media queries moeten helemaal onderaan staan */
@media only screen and (max-width: 1230px) {
  /* .widthTableAll {
    width: 69vw;
    overflow-x: auto;
  } */
}

@media only screen and (max-width: 1135px) {
  .productTable {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1100px) {
  .parentBoxTableBtn {
    width: 60vw;
    display: flex;
    justify-content: space-around	!important;
    margin-bottom: 20px;
    margin-top: 10px !important;
  }

  .btn-custom {
    margin-bottom: 0px !important;
  }

  .buttonsAddProducts {
    position: relative;
    top: 60px;
  }

  .titlePage {
    display: flex;
    justify-content: center;
    margin-top: 40px !important;
  }
}

@media only screen and (max-width: 991px) {
  .colorWheelAndInfo {
    flex-direction: column !important;
  }

  .sizeWhiteBox {
    width: 456px;
  }

  .modalCenter {
    padding: 10px;
  }

  .colorwheel {
    position: relative;
    left: 40px;
  }

  .colorInfo {
    flex-direction: row !important;
  }

  .rangeStyle {
    width: 100% !important;
  }

  .rangesContainer {
    min-width: fit-content !important;
    width: 100%;
  }
}

@media only screen and (max-width: 912px) {
  .buttonsAddProducts {
    position: relative;
    top: 0px;
  }
}

@media only screen and (max-width: 740px) {
  .productTable > div:nth-child(1) > div {
    flex-direction: column !important;
  }

  .productTable > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .parentBoxTableBtn {
    width: 60vw;
    display: flex;
    justify-content: space-around	!important;
    margin-bottom: 20px;
    margin-top: 10px !important;
  }

  .sizeWhiteBox {
    box-sizing: border-box; 
    width: 100%;
    max-width: 100%; 
  }

  .btn-custom {
    margin-bottom: 0px !important;
  }

  .addProductButtons {
    display: flex;
    flex-direction: column !important;
  }

  .colorwheel {
    position: relative;
    left: 65px;
  }
}

@media only screen and (max-width: 520px) {
  .colorwheel {
    position: relative;
    left: 20px;
  }
}

@media only screen and (max-width: 450px) {
  .colorwheel {
    position: relative;
    left: 0px;
  }

  .sizeWhiteBox {
    box-sizing: border-box; 
    width: 90%;
    max-width: 90%; 
  }

  .modalColorBackdrop {
    width: 100vw;
  } 

  .productTable > div:nth-child(1) {
    flex-direction: column !important;
  }

  .tableSearchBar {
    width: 100%;
  }

  .tableSearchBarContainer {
    margin-bottom: 20px;
  }

  .productTable > div:nth-child(3) {
    flex-direction: column-reverse !important;
  }

  .productTable > div:nth-child(3) > div {
    justify-content: center;
  }
}
