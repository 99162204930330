.tableUsers th > div {
  text-align: center !important;
}

.tableUsers th > div {
  text-align: center !important;
}

.tableUsers td  {
  text-align: center !important;
}

.tableUsers > div:first-child {
  margin-bottom: 15px;
}