.modalTopCustomers {
  background-color: white !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-bottom: 0px !important;
  border-bottom: transparent !important;
}

.modalCenterCustomers {
  background-color: white !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.modalBottomCustomers {
  background-color: white !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  padding-left: 30px !important;
  padding-right: 50px !important;
  border-top: transparent !important;
  padding-top: 0px !important;
}

.addButtonCustomer {
  background-color: #025a7a;
  border: none;
  color: white;
}

.secondaryTitles {
  font-size: 18px;
}

.SearchIcon {
  background-image: url('../Customer/AddCustomers/Icons/Search.svg'); /* Your custom arrow image */
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%; /* Moves the arrow right */
  background-size: 17px;
}

.widthContentMainbox {
  display: flex;
  justify-content: center;
}

.addButtonCustomer:focus, .addButtonCustomer:active, .addButtonCustomer:hover {
  background-color: #02516e !important;
  border: none !important;
  color: white !important;
}

.CustomerOverview {
  width: 100%;
  display: flex;
  justify-content: center;
}

.TableCustomers > div:nth-child(1) {
  margin-bottom: 20px;
}

.BirthdateConsumer {
  flex: 1;
}

.parentBoxSize {
  width: 100%;
}

.widthInputComponents {
  width: 97% !important;
}

.inputFieldAddCustomer {
  border: 2px solid #f8f8f8;
  border-radius: 20px;
  height: 35px;
  color: black;
  background-color: #f8f8f8 !important;
}

.modal.show {
  backdrop-filter: blur(5px);
}

.addIconSizeModals {
  width: 28px;
  cursor: pointer;
}

.switchNewCustomer {
  position: relative;
  display: inline-block;
  min-width: 60px;
  width: 60px;
  height: 26px;
}

.switchNewCustomer input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderNewCustomer {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cfcfcf00;
  -webkit-transition: .4s;
  transition: .4s;
}

.sliderNewCustomer:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  top: 3px;
  background-color: #ffffff;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .sliderNewCustomer {
  background-color: #009fe3;
}

input:focus + .sliderNewCustomer {
  box-shadow: 0 0 1px #efefef;
}

input:checked + .sliderNewCustomer:before {
  -webkit-transform: translateX(34px);
  -ms-transform: translateX(34px);
  transform: translateX(34px);
}

/* Rounded sliders */
.sliderNewCustomer.round {
  border-radius: 34px;
  background-color: #6a6a6a;
}

.sliderNewCustomer.round:before {
  border-radius: 50%;
}

.TableCustomers {
  width: 100%;
  margin-right: 30px;
  margin-left: 30px;
}

.dropdownsNewCustomers {
  padding: 0px;
  padding-left: 10px;
  width: 200px;
  appearance: none; /* Removes default browser styling */
  -webkit-appearance: none; /* Specifically for WebKit browsers */
  background-image: url('../Customer/AddCustomers/Icons/DrowpdownArrowModals.svg'); /* Your custom arrow image */
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%; /* Moves the arrow right */
  background-size: 10px;
}

.diffDateIcon {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  background: url('../Customer/AddCustomers/Icons/Calander.svg') no-repeat right 5px center / 20px 20px;
  cursor: pointer;
}

.diffDateIcon::-webkit-calendar-picker-indicator {
  position: absolute;
  opacity: 0;  /* Makes the default icon invisible but still functional */
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.diffDateIcon {
  appearance: none; /* Removes default browser styling */
  -webkit-appearance: none; /* Specifically for WebKit browsers */
  background-image: url('../Customer/AddCustomers/Icons/Calander.svg'); /* Your custom arrow image */
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%; /* Moves the arrow right */
  background-size: 15px;
}

/* .diffDateIcon {
  color: transparent;
} */

@media only screen and (max-width: 1135px) {
  .widthContentMainbox {
    width: 90%;
    display: flex;
    justify-content: center;
  }
  .TableCustomers {
    width: fit-content;
  }

  .CustomerOverview {
    overflow-x: scroll;
  }
}