.sidebar {
  position: fixed;
  height: 100vh;
  background: linear-gradient(180deg, rgba(0,159,227,1) 0%, rgba(2,90,122,1) 100%);
  position: fixed;
  width: 230px;
  border-top-right-radius: 50px;
  z-index: 2;
}

.widthSidebar {
  width: 210px;
}

.alignCenter {
display: flex;
align-items: center;
}

.iconSidebarCenter {
height: 35px;
margin-right: 10px;
}

.logo {
  max-width: 90%;    
  max-height: 20vh !important;
}

.collapseText {
  font-size: 14px;
}

.modeToggleIcons {
  height: 20px;
  width: 20px;
}

.modeToggleIconsSun {
  height: 25px;
  width: 25px;
}


.switchMode {
  position: relative;
  display: inline-block;
  min-width: 25px;
  height: 16px;
}

.switchMode input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderMode {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b4cbdf;
  -webkit-transition: .4s;
  transition: .4s;
}

.sliderMode:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 2px;
  top: 16%;
  background-color: #025a7a;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .sliderMode {
  background-color: white;
}

input:focus + .sliderMode {
  box-shadow: 0 0 1px white;
}

input:checked + .sliderMode:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(10px);
}

/* Rounded sliders */
.sliderMode.round {
  border-radius: 34px;
  border: 1px solid #efefef;

}

.sliderMode.round:before {
  border-radius: 50%;
}


.iconsSidebar {
  width: 23px;
  height: 23px;
  filter: brightness(0) invert(1);
}

.icon {
width: 20px;
height: 20px;
color: rgb(0, 0, 0);
color: rgb(0, 0, 0);
}

.icon-text {
  color: white;
  font-weight: 500;
  margin-bottom: 0 !important;
}

/* .widthSidebar > nav > div:nth-child(2) > div:nth-child(6) > a > p:nth-child(2) {
  color: white;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  width: 100px !important;
  line-height: 15px;
  position: relative;
  bottom: 2px;
}    */
 
.pageIndicator {
  font-weight: 600;
  color: black;
}


.widthSidebar > nav > div:nth-child(2) > div:nth-child(8) {
  position: absolute;
  bottom: 15px;
  width: 100%;
}  

.lastSidbarItemPlacment:nth-child(8) {
  bottom: 10%;
}

.burgerSidebar > div:nth-child(2) > div:nth-child(8) {
  position: absolute;
  bottom: 4px !important;
  width: 100%;
}   

.widthSidebar > nav > div:nth-child(2) > div:nth-child(8) > a > p:nth-child(2) {
  color: white;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  width: 100px !important;
  line-height: 15px;
  position: relative;
  bottom: 2px;
}  

.optionsSidebarProducts {
  position: relative;
  bottom: 10px;
  margin-left: 15px;
  cursor: pointer;
  font-size: small;
  border-left: 2px solid rgb(238, 238, 238);
  padding-left: 15px;
  color: black;
}

.pageIndicator {
  background-color: #e0e0e0c4;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  width:  fit-content;
}

.subPages {
  border-left: 4px solid #e0e0e0c4 !important;
}

.subPages:hover {
  border-left: 4px solid #0dcaf0 !important;
  color:#0dcaf0 !important;
}

.optionsSidebarProducts:hover {
  color: rgb(94, 94, 94);
}

/* Hamburger Button Styling */
.hamburger-button {
  position: fixed;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/* BurgerSidebar Styling */
.burgerSidebar {
  position: fixed;
  top: 0;
  z-index: 9;
  left: -250px;
  width: 250px;
  height: 100%;
  border-right: 2px solid #c2c2c2;
  background: #ffffff;
  transition: left 0.3s;
  color: #fff;
}

.burgerSidebar.open {
  left: 0;
}

.iconsCenter {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  width: fit-content;
}

/* Sidebar List Styling */
.burgerSidebar ul {
  padding: 20px 0;
  list-style: none;
  padding-left: 10px;
}

.burgerSidebar li {
  padding: 8px 0;
}

.dropdownSidebar {
  position: absolute;
  left: 100%;
  height: fit-content;
  background-color: #ffffff;
  min-width: 160px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 11px 8px 16px -5px rgba(0,0,0,0.2);
  opacity: 0; /* Start with 0 opacity */
  visibility: hidden; /* Make it invisible */
  transition: visibility 0s linear 0.1s, opacity 0.1s linear; /* Add transition with delay */
}

.icon-container:hover .dropdownSidebar {
  visibility: visible; /* Make it visible when hovered */
  opacity: 1; /* Fade to full opacity */
  transition-delay: 0.3s; /* Remove delay for visibility */
}

.textColorSidebarDropdown {
  color: gray !important;
}
.textColorSidebarDropdown:hover {
  color: #0dcaf0 !important;
  border-left: 5px solid #0dcaf0;
}

.icon-container:hover {
  background-color: #009fe3;
  border-radius: 20px;
}

.icon-container {
  width: 170px;
}

.activeSidebarItem {
  background-color: #009fe3;
  border-radius: 20px;
}

.dropdownSidebarContent {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 425px) {
  .widthSidebar {
      width: 80px;
  }
}

@media only screen and (max-width: 768px) {

  .iconsSidebar {
      width: 35px !important;
      height: 35px !important;
  }

  .sidebar {
      width: 70px !important;
  }

  .optionsSidebarProducts {
      position: relative;
      bottom: 5px !important;
      margin-left: 15px;
      right: 18px;
      cursor: pointer;
      font-size: small;
      border-left: 2px solid rgb(238, 238, 238);
      padding-left: 15px;
      color: black;
      font-size: medium;
  }

}

@media only screen and (max-width: 932px) {
  .iconsSidebar {
      width: 23px;
      height: 23px;
  }

  .optionsSidebarProducts {
      position: relative;
      bottom: 10px;
      margin-left: 15px;
      cursor: pointer;
      font-size: small;
      border-left: 2px solid rgb(238, 238, 238);
      padding-left: 15px;
      color: black;
      font-size: x-small !important;
  }

}

@media only screen and (max-width: 1150px) {
  .iconsSidebar {
      width: 23px;
      height: 23px;
  }

  .icon-text {
      padding-top: 0px ;
      font-size: small;
  }
}