.parentBoxEditUser {
  height: 71vh !important;
}

.inputFieldEditUser {
  border: 2px solid #e6e6e6e6;
  border-radius: 20px;
  height: 35px;
  color: black;
  background-color: white;
}

.inputFieldEditUser::placeholder {
  font-weight: 600;
  color: #bebebee6;
}

.inputFieldEditUser:focus, .inputFieldEditUser:active {
  border: 2px solid #c2c1c1e6 !important;
  outline: none !important;
}

.btnEditUser {
  background-color: #0586D3;
  color: white;
  border-color: #0586D3;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  height: 35px;
  line-height: 10px;
}

.btnEditUser:focus, .btnEditUser:active, .btnEditUser:hover {
  background-color: #036daa !important;
  color: white !important;
  border-color: #036daa !important;
}

.titlesAddUsers {
  display: flex;
  align-self: center !important;
}

.iconInfoStyle {
  margin-top: 3px;
  margin-left: 3px;
}

.iconInfoStyle:hover {
  cursor: pointer;
}