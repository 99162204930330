.categoryTable {
  width: 100%;
}

.categoryTable > div > .tableBackground > .table-drag td {
  width: 36.5vw;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc !important;
  border-top: 1px solid #ccc !important;
}

.categoryTable > div > .tableBackground > .table-drag td:first-child {
  width: 0.1vw;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc !important;
  border-top: 1px solid #ccc !important;
}