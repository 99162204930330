.ordersProcessBox {
    width: 70%;
}

.ordersCustomerBox {
    width: 28%;
}

.orderTable .newButtons:first-child {
    background-color: #009fe3 !important;
    color: white !important;
}

.orderTable .newButtons:first-child:hover {
    background-color: rgb(0, 106, 145) !important;
    color: white !important;
  }

/* orders edit */

.ordersEditContainer {
    display: flex;
    justify-content: space-between;
}

.clientSearch {
    padding: 2.5px;
    padding-left: 5px;
    padding-right: 28px;
}

.borderedSearchStyle {
    position: relative;
    width: 100%;
}

.clientSearch:focus {
    outline: none !important;
}

.clientSearching {
    border-radius: 10px;
    padding: 2.5px;
    padding-left: 5px;
    padding-right: 28px;
    border-bottom: 0px !important;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.clientSearching:focus {
    outline: none !important;
}


.clientSearchIcon {
    position: absolute;
    right: 10px; /* Adjust based on preference */
    top: 50%;
    transform: translateY(-50%);
}

.inputSaleOrder, .inputSaleOrder:focus, .inputSaleOrder:active {
    border: 1px solid #e5e5e5;
    outline: none;
    width: 60px;
    border-radius: 5px;
}

.euroSaleButton {
    width: 40px;
    background-color: white;
    border-radius: 5px 0px 0px 5px;
    color: #e5e5e5;
    border: 1px solid #e5e5e5;
}

.activeSaleType {
    background-color: #cfedfa !important;
    border: 1px solid #009fe3 !important;
    color: #009fe3 !important;
}

.percentageSaleButton {
    background-color: white;
    color: #e5e5e5;
    width: 40px;
    border-radius: 0px 5px 5px 0px;
    border: 1px solid #e5e5e5;
}

.amountCountButtons {
    background-color: white;
    border: 1px solid #ffffff;
    width: 30px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.amountParent {
    border: 2px solid #f8f8f8;
    padding: 0px 0px;
    display: flex;
    border-radius: 10px;
    flex-direction: row;
    justify-content: space-around;
}

.amountCount {
    border: 2px solid #f8f8f8;
    border-bottom: 0px ;
    border-top: 0px ;
}

.tableProductSearch td, .tableProductSearch th {
    border: 0px !important;
}

.tableProductSearch tr {
    border-bottom: 1px solid #c9c9c9 !important;
}

.noteSubtotal {
    height: 300px;
}


.btwSelect {
    border-radius: 10px;
    padding: 2.5px;
    padding-left: 5px;
    width: 125px;
    padding-right: 28px;
    border: 2px solid rgb(243, 243, 243);
    color: black;
    background-color: white;
}

.btwSelect:focus {
    outline: none !important;
}

.textareaStyleOrders {
    border: 2px solid rgb(243, 243, 243);
    border-radius: 10px;
    padding: 8px;
    height: 100%;
}

.textareaStyleOrders:focus {
    outline: none !important;
}

.saleInputStyle {
    border: 2px solid rgb(243, 243, 243);
    border-bottom-left-radius: 10px;
    border-Top-left-radius: 10px;
}

.saleTypeStyle {
    background-color: white;
    color: black;
    border: 2px solid rgb(243, 243, 243);
}

.dropdownZending {
    width: 30%;
    border-radius: 10px;
    padding: 2.5px;
    padding-left: 5px;
    padding-right: 28px;
    border: 2px solid rgb(243, 243, 243);
    color: black;
    background-color: white;
}

.dropdownZending:focus {
    outline: none;
}

.customersResults {
    position: absolute;
    background-color: white;
    width: 100%;
    padding: 10px;
    border-top: none !important;
    border: 2px solid rgb(243, 243, 243);
}

.customersResults td {
    text-align: center;
    width: 200px;
}

.customersSearch {
    position: relative;
}

.searchResults {
    position: relative;
}

.tableSearch {
    position: absolute;
    background-color: white;
    width: 100%;
    padding: 10px;
    border-top: none !important;
    border: 2px solid rgb(243, 243, 243);
}

.tableSearch td {
    text-align: center;
}

.selectDetails {
    border-radius: 10px;
    padding: 2.5px;
    padding-left: 5px;
    padding-right: 28px;
    border: 2px solid rgb(243, 243, 243);
    color: black;
    background-color: white;
}

.buttonsMake {
    display: flex;
    flex-direction: row;
}

.actionButtonsOrders:disabled{
    filter: brightness(0.8);
}

.activeSaleTypeStyle {
    background-color: rgb(221, 221, 221);
    border: 2px solid rgb(243, 243, 243);
}


.ordersEditLeftSide {
    width: 70%;
}

.orderTable > div {
    margin-bottom: 15px;
}

.ordersEditRightSide {
    width: 28%;
}

.ordersEditShowPriceTable {
    text-wrap: nowrap;
    width: 10%;
}

.ordersEditShowTaxTable {
    text-wrap: nowrap;
    width: 10%;
}

/* proccess orders */

.proccessOrdersContainer {
    display: flex;
    justify-content: space-between;
}

.proccessOrdersLeftSide {
    width: 64%;
}

.proccessOrdersRightSide {
    width: 34%;
}

.orderTable th:nth-child(2) > div {
    text-align: left !important;
}

.orderTable {
    margin-left: 15px;
    margin-right: 15px;
}

.NewOrder {
    display: flex;
    flex-direction: column;
}

.subTotaal {
    margin-bottom: 10px;
}

.orderTable th:first-child {
    width: 15px !important;
}

.orderTable th:nth-child(2) {
    width: 300px !important;
}

.orderTable td > div {
    text-align: center;
}

.orderTable td:nth-child(2) > div {
    text-align: start;
}

.orderTable td:nth-child(4) > div > div {
    text-align: center;
}

/* orders edit media */

@media only screen and (max-width: 1315px) {
    .orderTable {
        width: fit-content !important;
    }

    .orderTableOverview {
        overflow-x: scroll;
    }
}

@media only screen and (max-width: 1050px) {
    .noteSubtotal {
        display: flex;
        flex-direction: column;
    }

    .parentNote {
        width: 100%;
        padding-right: 0px !important;
        margin-bottom: 1rem;
    }

    .parentSubTotaal {
        width: 100%;
    }
}

@media only screen and (max-width: 943px) {
    .ordersEditLeftSide {
        width: 100%;
    }

    .OrderEdit {
        width: 95%;
    }

    .ordersEditRightSide {
        width: 100%;
    }
    
    .ordersEditContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 768px) {
    .NewOrder {
        margin-left: 15px;
        margin-right: 15px;
    }


}

@media only screen and (max-width: 600px) { 
    .subTotaal {
        width: 100% !important;
    }

    .dropdownDetails {
        justify-content: space-between;
    }
}

@media only screen and (max-width: 540px) { 
    .subTotaal {
        width: 100% !important;
        margin-bottom: 10px;
    }

    .discountOrder {
        flex-direction: column;
    }

    .buttonswitch {
        width: 100%;
    }

    .buttonswitch > input:nth-child(1) {
        width: 100%;
    }

    .subTotal {
        display: flex;
        flex-direction: column-reverse;
    }

    .shippingCosts > .ShippingCostInput {
        width: 100% !important;
    }
    
    .shippingCosts {
        margin-bottom: 10px;
    }

    .dropdownZending {
        width: 100%;
    }

    .dropdownDetails {
        display: flex; 
        justify-content: space-between;
    }

    .sellLocation {
        width: 45%;
    }

    .ordersProcessing {
        width: 45%;
    }
    
}

@media only screen and (max-width: 400px) { 
    .orderEditScroll {
        width: 500px;
    }

    .dropdownDetails {
        display: flex; 
        flex-direction: column;
    }

    .sellLocation {
        width: 100%;
        margin-left: 0px !important;
    }

    .ordersProcessing {
        width: 100%;
        margin-right: 0px !important;
    }

    .orderOverview {
        width: 100% !important;
    }
}

/* proccess orders media */

@media only screen and (max-width: 943px) {
    .proccessOrdersLeftSide {
        width: 90%;
    }

    .proccessOrdersRightSide {
        width: 90%;
    }
    
    .proccessOrdersContainer {
        display: block;
    }
}
