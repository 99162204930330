@import url(https://fonts.googleapis.com/css?family=Nunito);

label, h1, h2, h3, h4, h5, h6, a, p {
    font-family: 'Nunito';
}

.Nunito {
    font-family: 'Nunito';
}

.inputField {
    border: none;
    border-radius: 50px;
    padding-left: 10px;
}

.standardInput {
    border: 2px solid #f8f8f8;
    border-radius: 20px;
    height: 35px;
    color: black;
    background-color: #f8f8f8 !important;
}

.standardInput:focus {
    border: 2px solid #818181;
    outline: none;
}

.fwPerfect {
    font-size: 15px;
    font-weight: 500;  
}

.SearchIcon {
    background-image: url('../Customer/AddCustomers/Icons/Search.svg');
    /* Your custom arrow image */
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) 50%;
    /* Moves the arrow right */
    background-size: 17px;
}

.linkColor {
    color: #009fe3;
    font-weight: 600;
}

.standardDropdownArrow {
    appearance: none;
    /* Removes default browser styling */
    -webkit-appearance: none;
    /* Specifically for WebKit browsers */
    background-image: url('../Customer/AddCustomers/Icons/DrowpdownArrowModals.svg');
    /* Your custom arrow image */
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) 50%;
    /* Moves the arrow right */
    background-size: 10px;
}

.dropdownArrowSmall {
    background-size: 6px !important;
}

.borderBottomGray {
    border-bottom: 2px solid #e5e5e5;
}

.uploaderStyle {
    border: 2px dashed #afafaf;
    height: 300px;
    background-color: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* accordion styling */

.containerAccordion {
   border-bottom: 1px solid #eee;
}

/* accordion styling */

.containerAccordion {
    border-bottom: 1px solid #eee;
    background-color: #ffffff;
}

.accordion {
    background-color: #ffffff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: background-color 0.4s;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .accordion:hover, .accordion.active {
    background-color: #ffffff; 
  }
  
  .arrow {
    transition: transform 0.4s ease-in-out;
  }
  
  .arrow.active {
    transform: rotate(-180deg);
  }
  
  .panel {
    padding: 0px 0px 0px 18px;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    height: 0;
    transition: height 0.4s ease-in-out, padding 0.4s ease-in-out;
  }
  
  .panel-content {
    padding: 18px 0px;
    padding-left: 18px;
    padding-top: 0px;
  }

  .buttonsAccordion {
    border-radius: 5px !important;
    padding: 4px !important;
  }

/* end accordion styling */

.defaultSwitch {
    position: relative;
    display: inline-block;
    min-width: 60px;
    width: 60px;
    height: 26px;
}

.defaultSwitch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.defaultSlider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #cfcfcf00;
    -webkit-transition: .4s;
    transition: .4s;
}

.defaultSlider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    top: 3px;
    background-color: #ffffff;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.defaultSlider {
    background-color: #009fe3;
}

input:focus+.defaultSlider {
    box-shadow: 0 0 1px #efefef;
}

input:checked+.defaultSlider:before {
    -webkit-transform: translateX(34px);
    -ms-transform: translateX(34px);
    transform: translateX(34px);
}

/* Rounded sliders */
.defaultSlider.round {
    border-radius: 34px;
    background-color: #6a6a6a;
}

.defaultSlider.round:before {
    border-radius: 50%;
}

body {
    background-color: #e2eefb;
    height: 100vh;
}

.inputField:focus {
    outline: none;
}

.inputTitle {
    margin-bottom: 5px;
    font-weight: 600;
    color: #000000;
}

a:first-child[tabindex="-1"],
div:first-child[class="tox-notifications-container"] {
    display: none;
}

.whiteBox {
    background-color: white;
    border-radius: 15px;
    padding: 10px;
}

.fileUploader>label {
    border: 2px dashed #707070;
    border-radius: 0px;
    max-width: none;
    height: 150px;
    background-color: white;
}

.fileUploader>label>svg {
    display: none;
}

.fileUploader>label>div {
    display: flex;
    justify-content: center;
}

.fileUploader>label>div>span {
    visibility: visible;
}

.fileUploader>label>div>span::before {
    visibility: visible;
    position: relative;
    left: 75px;
    white-space: nowrap;
}

.optionsList {
    border: none;
    border-radius: 50px;
    padding-left: 10px;
    color: black;
    background-color: white;
}

.optionsList:focus {
    outline: none;
}

::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #6fc4e9;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #599dbb;
}

.cursorPointer {
    cursor: pointer;
}

.boxStyle {
    width: 175px;
    height: 150px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 2px 2px 4px #bbbbbb;
    transition: .5s !important;
    margin-bottom: 35px;
}

.boxStyle:hover,
.boxStyle:active {
    transform: scale(1.1);
    width: 175px;
    height: 150px;
    border-radius: 10px !important;
    background-color: #ffffff !important;
    box-shadow: 10px 10px 5px rgba(187, 187, 187, 0.5) !important;
}

.boxStyle:active {
    width: 175px;
    height: 150px;
    border-radius: 10px !important;
    background-color: #c0d2dd !important;
    box-shadow: 2px 2px 4px #bbbbbb !important;
}

.buttonStyleLight {
    background-color: #ffffff;
    color: #000000;
    font-weight: 500;
    font-size: small;
    border: none;
    padding: 10px;
    border-radius: 50px;
}

.hover {
    cursor: pointer;
}

.btn-custom {
    padding: 10px;
    border-radius: 200px !important;
    font-weight: 600 !important;
    display: flex;
    align-items: center;
    height: 40px;
    margin: 20px;
    background-color: white;
    text-decoration: none;
    color: black;
    height: 40px;
    outline: none !important;
}

.buttonStyle {
    background-color: white;
    border: none;
    height: 40px !important;
    color: #000000;
    font-weight: 500;
    border-radius: 50px;
    outline: none;
    padding: 0px 20px 0px 20px;
    transition: .2s;
}

.buttonStyle:hover,
.buttonStyle:active {
    background-color: #929292 !important;
    color: #000000 !important;
    font-weight: 500 !important;
    border-radius: 50px !important;
}

.dropdown-list {
    position: absolute;
    padding: 0px;
    width: 100%;
    z-index: 5;
    transition: 2s;
}

.dropdown-item {
    margin: 0px;
    padding: 5px;
    padding-left: 20px;
    width: 100%;
    background-color: white;
    border-bottom: 2px solid #efefef;
}

.dropdown-item:hover {
    cursor: pointer;
    background-color: #efefef;
}

.inActive-dropdown-list {
    display: none;
}

.dropdown-item:first-child {
    border-top: 2px solid #efefef;
}

.dropdown-item:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

/* text editor styling*/

.jodit-editor>div>div>div:nth-child(4) {
    display: none;
}

.jodit-editor>div>div>div:first-child {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    background-color: #f8f8f8 !important;
    border-bottom: 2px solid #e5e5e5;
}

.jodit-container:not(.jodit_inline) {
    background-color: #f8f8f8 !important;
    border: 1px solid #ffffff !important;
    border-radius: 15px;
}

.jodit-wysiwyg {
    background-color: #f8f8f8 !important;
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    min-height: 300px !important;
}

.jodit-status-bar {
    display: none !important;
}

.jodit-editor>div>div {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}

/* end text editor styling */

/* StandardButtons styling */

.DarkStandardButton {
    font-family: 'Nunito';
    background-color: #6a6a6a;
    color: white;
    font-weight: 600;
    padding: 5px 50px;
    border-radius: 20px;
    outline: 0px solid #6a6a6a !important;
    border: 0px solid #6a6a6a !important;
}

.lightDarkGrayStandardButton {
    font-family: 'Nunito';
    background-color: #e5e5e5;
    color: black;
    font-weight: 600;
    padding: 5px 50px;
    border-radius: 20px;
    outline: 0px solid #e5e5e5 !important;
    border: 0px solid #e5e5e5 !important;
}

.lightGrayStandardButton {
    font-family: 'Nunito';
    background-color: #f8f8f8;
    color: black;
    font-weight: 600;
    padding: 5px 50px;
    border-radius: 20px;
    outline: 0px solid #f8f8f8 !important;
    border: 0px solid #f8f8f8 !important;
}

.lightBlueStandardButton {
    font-family: 'Nunito';
    background-color: #009fe3;
    color: white;
    font-weight: 600;
    padding: 5px 50px;
    border-radius: 20px;
    outline: 0px solid #009fe3 !important;
    border: 0px solid #009fe3 !important;
}

.darkBlueStandardButton {
    font-family: 'Nunito';
    background-color: #025a7a;
    color: white;
    font-weight: 600;
    padding: 5px 50px;
    border-radius: 20px;
    outline: 0px solid #025a7a !important;
    border: 0px solid #025a7a !important;
}

.whiteStandardButton {
    font-family: 'Nunito';
    background-color: #ffffff;
    color: white;
    font-weight: 600;
    padding: 5px 50px;
    border-radius: 20px;
}

.DarkStandardButton:hover,
.DarkStandardButton:active,
.DarkStandardButton:focus {
    background-color: #575757 !important;
    color: white !important;
    outline: 0px solid #e7e7e7 !important;
    border: 0px solid #e7e7e7 !important;
}

.lightBlueStandardButton:hover,
.lightBlueStandardButton:active,
.lightBlueStandardButton:focus {
    background-color: #0092d1 !important;
    color: white !important;
    outline: 0px solid #0092d1 !important;
    border: 0px solid #0092d1 !important;
}

.darkBlueStandardButton:hover,
.darkBlueStandardButton:active,
.darkBlueStandardButton:focus {
    background-color: #00749e !important;
    color: white !important;
    outline: 0px solid #00749e !important;
    border: 0px solid #00749e !important;
}

.lightGrayStandardButton:hover,
.lightGrayStandardButton:active,
.lightGrayStandardButton:focus {
    background-color: #e7e7e7 !important;
    color: black !important;
    outline: 0px solid #e7e7e7 !important;
    border: 0px solid #e7e7e7 !important;
}

.lightDarkGrayStandardButton:hover,
.lightDarkGrayStandardButton:active,
.lightDarkGrayStandardButton:focus {
    background-color: #c9c9c9 !important;
    color: black !important;
    outline: 0px solid #c9c9c9 !important;
    border: 0px solid #c9c9c9 !important;
}

/* end StandardButtons styling */