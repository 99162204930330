.colorSettings {
  width: 	100%;
}

.buttonColors {
  min-width: fit-content;
  height: 35px !important;
  line-height: 20px !important;
}

.nieuwColor {
  background-color: #79b893;
  color: white;
  font-weight: 500;
  border-radius: 50px;
}

.nieuwColor:focus, .nieuwColor:active , .nieuwColor:hover {
  background-color: #5b8a6e !important;
  color: white !important;
  font-weight: 500 !important;
  border-radius: 50px !important;
}

.editColor {
  background-color: #79cffb;
  color: white;
  font-weight: 500;
  border-radius: 50px;
}

.editColor:focus, .editColor:active , .editColor:hover {
  background-color: #65afd4 !important;
  color: white !important;
  font-weight: 500 !important;
  border-radius: 50px !important;
}

.colorSettings > div > div > .table-drag td {
  width: 18vw;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc !important;
  border-top: 1px solid #ccc !important;
}

.colorSettings > div > div > .table-drag td:first-child {
  width: 0.1vw;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc !important;
  border-top: 1px solid #ccc !important;
}