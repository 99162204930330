.navbar {
  position: fixed !important;
  width: calc(100% - 230px);
  z-index: 	2 !important;
  height: 100px;
  left: 230px;
  background-color: #e2eefb;
}

.warehouseBox {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #79b893;
}

.searchBox {
  position: relative;
  bottom: 7px;
}

.warehouseIcon {
  width: 60%;
  height: 60%;
  filter: brightness(0) invert(1);
}

.searchbar {
  border: none;
}

.searchbarNav::placeholder {
  border: none;
  font-weight: 500;
  color: #000000;
}

.searchbarNav::placeholder {
  border: none;
  font-weight: 500;
  color: #000000;
}

.searchbar:focus {
  outline: none;
}

.iconHeightNav {
  padding: 0 !important;
}

.iconsNav {
  width: 22px;
  height: 22px;
}

.profile-picture {
  width: 48px; /* Set the desired width */
  height: 48px; /* Set the desired height */
  position: relative;
  bottom: 12px;
  right: 5px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #c9c9c9;
}

.profile-picture img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.dot {
  position: relative;
}

.dot::after {
  content: '';
  position: absolute;
  top: 4px;
  right: -2px;
  width: 4px;
  height: 4px;
  background-color: red;
  border-radius: 50%;
}

.mailIconColorInvert {
  filter: invert(1)
}

.mailIconPosistion {
  position: relative;
  bottom: 2px
}

.indicatorIcons {
  width: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.searchIcon {
  display: flex;
  align-self: center;
  width: 20px;
  height: 20px;
}

.searchbarNav::placeholder {
  color: #000000;
 }

.searchbarNav.show {
 width: 100% !important;
}

.searchbarNav:focus {
  outline: none;
}


.searchBox-expanded {
  width: 130px;
  display: flex;
}

.logOutIcon {
  position: relative;
  top: 5px;
  margin-right: 5px;
}

@media only screen and (max-width: 1430px) {
  .navbar {
    width: 80% !important;
    left: 21.5% !important;
  }
}

@media only screen and (max-width: 1112px) { 
  .navbar {
    width: 95% !important;
    left: 7% !important;
  }
}


@media only screen and (max-width: 943px) {
  .indicatorIcons {
    display: none;
  }

  .iconsNavDrop {
    width: 20px;
    height: 20px;
  }

  .indicatorIconsDrop {
    display: flex;
    position: absolute;
    bottom: 0px;
    left: 10px;
  }

  .searchbarNav.show {
    width: 100px !important;
   }
  
  .searchIcon {
    margin-right: 0px !important;
    padding-right: 0px !important;
  }

  .iconsNav {
    position: relative;
  }
}

/* @media only screen and (max-width: 550px) {

  .Werknemer {
      border-left: 0px solid #000000;
      border-left: 0px solid #000000;
      height: 30px;
      margin-top: 1rem;
      padding-left: 0px !important;
      margin-left: 0px !important;
   }

   .iconHeightNav {
      display: flex;
      flex-direction: column;
      padding-top: 1rem !important;
  }

  .iconHeightNavDash {
    display: flex;
    flex-direction: column;
    padding-top: 1rem !important;
    padding-left: 0px !important;
}

  .dropdownNav {
    position: relative;
    display: inline-block;
    height: 35px;
    border-left: 0px solid #000000 !important;
    border-left: 0px solid #000000 !important;
  }

  .iconsNavBell {
     margin-right: 0px !important;
  }

  .iconBox {
      margin: 0px !important;
      display: flex;
      justify-content: center;
      margin-bottom: 25px !important;
  }

}
*/
@media only screen and (max-width: 630px) {
  .navbar {
    width: 93% !important;
    left: 10% !important;
  }
  .pageTitle {
    display: none !important;
  }
}

@media only screen and (max-width: 550px) {
  .navbar {
    padding-left: 20px !important;
    margin-left: 2px;
  }

  .usernameText {
    display: none;
  }


}

@media only screen and (max-width: 488px) {
  .searchbarNav {
    border: none;
    width: 6vw;
    position: relative;
    display: none;
 }

  .navbar {
    width: 90% !important;
    left: 12% !important;
    padding-left: 15px !important;
  }

 .userName {
  display: none;
 }

 .logOutText {
  display: none;
 }

 .logOut {
  position: absolute;
  top: 276px !important;
 }

 .dropdown-content {
  width: 200px !important;
  }

  .line {
    width: 200px !important;
  }

}

@media only screen and (max-width: 360px) {
  .iconBox {
    margin-right: 20px !important;
  }

  .navbar {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
}
/* The container <div> - needed to position the dropdown content */
.dropdownNav {
  position: relative;
  display: inline-block;
  height: 35px;
  border-left:2px solid #c9c9c9;
}
/**/
.userInfoMenu{
 position:relative !important;
 top: 5px;
 top: 5px;
 z-index: 999 !important;
}

.profile-container {
  display: flex;
  align-items: center;
}


.link {
  text-decoration: none;
  color: gray;
}

.link:hover {
  text-decoration: none;
  color: rgb(99, 99, 99);
}

.logOut {
  color: #414141;
  position: absolute;
  top: 272px;
  right: 20px;
  text-decoration: none;
}

.logOut:hover {
  color: #888888;
  cursor: pointer;
  text-decoration: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  border-radius: 15px;
  bottom: -250px;
  right: -15px;
  padding-left: 2rem;
  height: 300px;
  width: auto;
  z-index: 998 !important;
  background-color: white;
  box-shadow: 3px 3px 5px 1px rgb(180, 180, 180);
  transition: opacity 0.5s;
}

.userInfoMenu ~ .dropdown-content,
.dropdown-content:hover {
  display: flex;
  flex-direction: column;
}

.dropdownIndicator {
  position: relative;
  top: 4px;
  left: 5px;
}


.dropdownNav.clicked .dropdownIndicator {
  animation: spin 0.5s forwards;
}

.dropdownNav:not(.clicked) .dropdownIndicator {
  animation: spin-back 0.5s forwards;
}

.hover-area {
  display: none;
  position: absolute;
  height: 50px;
  width: 100%;
  top: -50px;
  left: 0;
  z-index: 997;
}
.userInfoMenu:hover ~ .hover-area {
  display: block;
}

.line {
  width: 100%;
  position: absolute;
  right: 0px;
  top: 270px;
  padding-left: 5vw !important	;
  border-bottom: 1px solid #d4d4d4;
}

.dropdownListedItems {
  position: relative; 
  top: 80px;
}

.links {
  text-decoration: none;
  margin-bottom: 25px !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes spin-back {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/* Animations */
@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeOut{
  0% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes fadeOut{
  0% {opacity: 1;}
  100% {opacity: 0;}
}