.modal-content {
  background-color: transparent !important;
  border: none !important;
}

.modalTop {
  border-bottom: transparent !important;
  justify-content: end ;
  padding:  30px 30px;
  background-color: #FFFFFF !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.modalCenter {
  display: flex !important;
  padding:  0px 50px;
  min-height: fit-content !important;
  background-color: #FFFFFF !important;
}

.modalBottom {
  border-top: none !important;
  padding: 30px 30px;
  background-color: #FFFFFF !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.closeModal {
  height: 40px;
  width: 40px;
  display: flex;
}